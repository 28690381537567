import { useEffect } from 'react'

import { useTheme } from '@context/ThemeContext'
import { FullScreenFeedLayout } from '@desktop/layouts/FullScreenFeedLayout'
import { MetaTags } from '@desktop/MetaTags'
import { useTrackPageView } from '@hooks/analytics/useTrackPageView'
import { useIsDesktop } from '@hooks/useIsDesktop'
import runtimeConfig from '@lib/RuntimeConfig'
import VerticalFeed from '@mobile/VerticalFeed'
import { filterResourcesByType } from '@models/RecommendResponse'
import { UrlMedia } from '@pages/url/components/UrlMedia'
import { VideoPlayerContextProvider } from '@pages/url/components/VideoPlayerContext'
import feedApi from '@redux/api/feedApi'
import { selectMediaTypes } from '@redux/slices/appSlice'
import {
  insertFeedItems,
  selectCurrentFeedItemKey,
  selectCurrentUrl,
  selectFilteredIds,
  setCurrentFeedItemKey,
} from '@redux/slices/feedSlice'
import { useAppDispatch, useAppSelector } from '@redux/store/store'
import Taglines from './tagline'

const LoggedOutHome = () => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const currentPath = useAppSelector(state => state.router.location.pathname)
  const {
    page,
    identifier: { slug },
    pageSize,
    hasReachedEnd,
    _instanceId,
  } = useAppSelector(state => state.feed)
  const mediaTypes = useAppSelector(selectMediaTypes)
  const { isLoginModalOpen, isLoginHeroOpen } = useAppSelector(state => state.loginModal)

  useEffect(() => {
    theme.setForcedTheme('dark')
    return () => theme.setForcedTheme(undefined)
  }, [theme, theme.resolvedTheme])

  useTrackPageView()

  const filterIds = useAppSelector(selectFilteredIds)
  const { data: feed } = feedApi.useGetFeedDataQuery(
    {
      page,
      pageSize,
      slug,
      mediaTypes: Array.from(mediaTypes),
      filterIds,
      _instanceId,
      contextId: null,
    },
    { skip: hasReachedEnd || isLoginModalOpen }
  )

  const { items } = feed ?? {}
  const urls = filterResourcesByType(items ?? [], 'url')
  const currentFeedItemKey = useAppSelector(selectCurrentFeedItemKey)

  const { copy: TaglineCopy } = Taglines[currentPath as keyof typeof Taglines] ?? Taglines.default

  const isDesktop = useIsDesktop()

  // Initialize feed with the provided URL
  useEffect(() => {
    if (currentFeedItemKey) return

    if (items) {
      dispatch(setCurrentFeedItemKey(urls[0].url_id.toString()))
      dispatch(
        insertFeedItems({
          index: 0,
          items,
        })
      )
    }
  }, [urls, items, dispatch, currentFeedItemKey])

  const currentUrl = useAppSelector(selectCurrentUrl)

  return (
    <>
      <MetaTags
        title={`Mix — ${TaglineCopy.heading}`}
        description={TaglineCopy.subheading}
        permalink={runtimeConfig().publicRuntimeConfig.baseUrl}
      />
      {isDesktop ? (
        <div className="flex size-full flex-col overflow-y-scroll scrollbar-hide md:flex-row" id="scrolling-grid">
          <FullScreenFeedLayout currentUrl={currentUrl}>
            <VideoPlayerContextProvider key={currentUrl?.url_id}>
              {currentUrl ? (
                <UrlMedia url={currentUrl} isActive={!isLoginHeroOpen && !isLoginModalOpen} />
              ) : (
                <div className="m-auto mt-5 size-[90%] animate-pulse bg-contrast/10"></div>
              )}
            </VideoPlayerContextProvider>
          </FullScreenFeedLayout>
        </div>
      ) : (
        <VideoPlayerContextProvider key={currentFeedItemKey}>
          <VerticalFeed renderLoginSlide />
        </VideoPlayerContextProvider>
      )}
    </>
  )
}

export default LoggedOutHome
